<template lang="pug">
  v-card().video-watcher
    v-toolbar.custom-toolbar-wrapper(dark="" height="75px" color="primary")
      .toolbar-header.mx-auto
        h2 {{model.title}}
      v-btn.toolbar-btn(icon dark absolute right  @click="$emit('close')")
        v-icon(size="30") mdi-close-box
    <!--v-card-title-->
      <!--.display-3 {{model.title}}-->
      <!--v-spacer-->
        <!--v-icon(aria-label="Close" @click="$emit('close')")-->
          <!--| mdi-close-->
    v-card-text.pa-0
      v-layout().d-flex.media-preview
        div(v-if="model.type.includes('video') && owned(model)").mx-auto
          video-player.vjs-custom-skin(ref="videoPlayer" :options="playerOptions" :playsinline="true" @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)" @loadeddata="onPlayerLoadeddata($event)" @waiting="onPlayerWaiting($event)" @playing="onPlayerPlaying($event)" @timeupdate="onPlayerTimeupdate($event)" @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)" @ready="playerReadied" @statechanged="playerStateChanged($event)")
        div(v-else-if="model.type.includes('image') && owned(model)")
          img(:src="model.url" style="width: 100%; max-height: 300px object-fit:contain;")
        div(v-else)
          img(:src="model.thumbnail" style="width: 100%; max-height: 300px; object-fit:contain;")
      v-col.mx-auto(max-width="400" tile="" ).pa-4
        v-tabs(v-model="tab" centered  grow )
          v-tabs-slider
          v-tab(v-for="i in tabs" :key="i.name" :href="`#tab-${i.name}`")
            |{{ i.name }}
            v-icon(v-if="icons") mdi-phone
          v-tab-item( value="tab-description")
            v-card(flat="" tile="")
              v-card-text
                p.mt-2.text-justify {{model.description}}
                <!--v-row().justify-space-around-->
                  <!--.display-3 $ {{model.price}} usd-->
                  <!--v-btn(color="primary" large @click="$emit('confirmationDialog')") buy-->
          v-tab-item( value="tab-details")
            v-card(flat="" tile="")
              v-card-text
                .d-flex.justify-space-between(cols='12' style="width: 100%")
                  span Media type
                  |{{splitted(model.type)[0] | ''}}
                .d-flex.justify-space-between(cols='12' style="width: 100%")
                  span Format
                  |{{splitted(model.type)[1] | ''}}
                .d-flex.justify-space-between(cols='12' style="width: 100%" v-if="model.type.includes('video')")
                  span Duration
                  |{{model.duration}}
                v-divider
                .d-flex.justify-space-between(cols='12' style="width: 100%")
                  span Category
                  |{{model.category}}
                v-divider
                .d-flex.justify-space-between(cols='12' style="width: 100%" v-if="model.subcategory")
                  span Subcategory
                  |{{model.subcategory}}
                v-divider
                .d-flex.justify-space-between(cols='12' style="width: 100%")
                  span Occurrence Date
                  |{{model.date | moment}}
                v-divider
                .d-flex.justify-space-between(cols='12' style="width: 100%" v-if="model.created")
                  span Submitted Date
                  |{{model.created | moment}}
                v-divider(v-if="model.created")
                .d-flex.justify-space-between(cols='12' style="width: 100%")
                  span State
                  |{{model.location.state}}
                v-divider
                .d-flex.justify-space-between(cols='12' style="width: 100%")
                  span Locality
                  |{{model.location.locality}}
      v-row
        gmap-map(:center="model.location.latlng" :zoom="14" style="width:200px;  height: 150px;" :options="optionMap").mx-auto
          gmap-marker(:icon='markerOptions'  :animation="2" :position="model.location.latlng" )

</template>
<script>
  import 'video.js/dist/video-js.css'
  import { videoPlayer } from 'vue-video-player'
  import videoPlaceholder from '@/assets/images/placeholder.png'
  import defaultIcon from '@/assets/icons/search/map-marker.png'
  import moment from 'moment'
  export default {
    components: {
      videoPlayer,
    },
    props: {
      model: Object,
      default: {},
    },
    filters: {
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY')
      },
    },
    data: () => ({
      defaultIcon: defaultIcon,
      tab: null,
      tabs: [
        {
          name: 'description',
        },
        {
          name: 'details',
        },
      ],
      optionMap: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        gestureHandling: 'greedy',

      },
    }),
    computed: {
      downloads () {
        return this.$store.getters['downloads/getDownloads']
      },
      center () {
        return {
          lat: this.model.location.lat,
          lng: this.model.location.lng,
        }
      },
      markerOptions () {
        return {
          url: defaultIcon,
          size: { width: 70, height: 70, f: 'px', b: 'px' },
          scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
        }
      },
      playerOptions () {
        return {
          height: '250px',
          width: window.innerWidth < 600 ? window.innerWidth : '600px',
          language: 'en',
          playbackRates: [0.5, 1.0, 1.5, 2.5, 3.0],
          sources: [{
            type: 'video/mp4',
            src: this.model.url,
          }],
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
          },
          poster: this.model.thumbnail || videoPlaceholder,
          fluid: false,
        }
      },
    },
    created () {

    },
    methods: {
      splitted (someString) {
        const message = someString.toString()
        return message.split('/') // Gets the first index where a space occours
      },
      // if user owns the video already
      owned (item) {
        const vm = this
        if (vm.downloads) {
          const found = vm.downloads.find(element => element.url === item.url)
          if (found) {
            return true
          }
        }
        return false
      },
      // listen event
      onPlayerPlay (player) {
        // console.log('player play!', player)
      },
      onPlayerPause (player) {
        // console.log('player pause!', player)
      },
      onPlayerEnded (player) {
        // console.log('player ended!', player)
      },
      onPlayerLoadeddata (player) {
        // console.log('player Loadeddata!', player)
      },
      onPlayerWaiting (player) {
        // console.log('player Waiting!', player)
      },
      onPlayerPlaying (player) {
        // console.log('player Playing!', player)
      },
      onPlayerTimeupdate (player) {
        // console.log('player Timeupdate!', player.currentTime())
      },
      onPlayerCanplay (player) {
        // console.log('player Canplay!', player)
      },
      onPlayerCanplaythrough (player) {
        // console.log('player Canplaythrough!', player)
      },
      // or listen state event
      playerStateChanged (playerCurrentState) {
        // console.log('player current update state', playerCurrentState)
      },
      // player is ready
      playerReadied (player) {
        // seek to 10s
        // console.log('example player 1 readied', player)
        player.currentTime(10)
        // console.log('example 01: the player is readied', player)
      },

    },

  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .media-preview{
      min-width: 100%;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
      min-height: 200px;
    }
    .video-watcher{
      max-width: 600px;
      overflow-x: hidden;
      @media(max-width: 600px){
        height: 100%;
      }

    }
    .video-player-box{
        max-height: 400px;
        margin: auto
    }
    .video-js .vjs-tech {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 500px;
      max-height: 360px;
    }
    .video-js.vjs-big-play-button{
      margin: auto;
      top:0;
      bottom:0;
      left:0;
      right: 0;
    }
    .vjs-big-play-button{
      position: absolute;
      margin: auto;
      right: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      top: 0 !important;
    }
    /*.vjs-big-play-button{*/
      /*position: absolute;*/
      /*margin: auto;*/
      /*right: 0 !important;*/
      /*left: 0 !important;*/
      /*bottom: 0 !important;*/
      /*top: 0 !important;*/
    /*}*/
</style>
